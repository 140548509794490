import * as React from 'react';
import { render } from 'react-dom';
import { useLoginOnStartup } from '@employee-experience/common/lib/useLoginOnStartup';
import { ShellWithStore } from './ShellWithStore';
import { CoherenceCustomizations } from '@cseo/styles/lib/CoherenceCustomizations';
import { Customizer } from 'office-ui-fabric-react/lib/Utilities';
import { Footer, ShellStyles } from '@employee-experience/shell';
import { BrowserRouter } from 'react-router-dom';
import Nav from './SupportPage/Nav';
import { Main } from '@employee-experience/shell/lib/Main';
import { Routes } from './Routes';
import { Footer as AskHRFooter } from './Shared/Footer/Footer';
import { initializeCustomIcons } from './shared/initializeCustomIcons';
import { Header as EEHeader } from '@employee-experience/shell/lib/Header';
import { ComponentContext } from '@employee-experience/common';
import { initializeOBFeedback } from '@cseo/controls/lib/OfficeBrowerFeedback';
import { useHistory } from 'react-router-dom';

import { AppStyles } from './App.styles';

function saveBotOriginToSession() {
    const query = new URLSearchParams(window.location.search);
    const origin = 'botOrigin';
    const extractBotOrigin = query.get(origin.toLowerCase()) || query.get(origin);
    window.sessionStorage.setItem('botOriginKey', extractBotOrigin);
}

const { navStyle, bannerStyle } = AppStyles;

initializeCustomIcons();

initializeOBFeedback(
    __OCV_APP_ID__,
    __IS_PRODUCTION__ ? 'Prod' : 'Int',
    '/ocv/officebrowserfeedback.min.js',
    '/ocv/officebrowserfeedback.min.css',
    '/ocv/intl/',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    { featureArea: isAvaRequested() ? 'AskHR-VA' : 'AskHR-Support' },
    undefined,
    undefined,
    undefined,
    undefined,
    false
);

function isAvaRequested(): boolean {
    const { pathname, search } = window.location;

    if (pathname === '/' && !search.toLowerCase().includes('botorigin'))
        window.location.href = '/support';

    if (
        pathname.toLowerCase().includes('support') ||
        pathname.toLowerCase().includes('hrhelpform') ||
        pathname.toLowerCase().includes('search')
    )
        return false;

    return true;
}

function App(): React.ReactElement {
    saveBotOriginToSession(); //Save botorigin to sessionstorage.
    useLoginOnStartup();

    const { telemetryClient } = React.useContext(ComponentContext);
    const history = useHistory();

    const handleSearched = (value: string): void => {
        //history.push(`/search?q=${encodeURIComponent(value)}`);
        window.location.href = `/search?q=${encodeURIComponent(value)}`;
    };

    return (
        <Customizer {...CoherenceCustomizations}>
            <ShellStyles />
            <BrowserRouter>
                {isAvaRequested() && (
                    <>
                        <EEHeader
                            headerLabel={'AskHR Virtual Assistant'}
                            appNameSettings={{
                                label: 'AskHR Virtual Assistant',
                                linkUrl: window.location.href,
                            }}
                            farRightSettings={{
                                helpSettings: undefined,
                                feedbackSettings: {
                                    panelSettings: {
                                        ocvButtonIsFocused: false,
                                        onClick: () => {
                                            return true;
                                        },
                                        launchOptions: {
                                            categories: {
                                                show: false,
                                                customCategories: [],
                                            },
                                        },
                                    },
                                },
                            }}
                            telemetryHook={telemetryClient}
                        />
                        <Routes />
                    </>
                )}
                {!isAvaRequested() && (
                    <>
                        <EEHeader
                            headerLabel={'AskHR Support'}
                            appNameSettings={{
                                label: 'AskHR Support',
                            }}
                            searchSettings={{
                                placeholder: `Search HRweb...`,
                                onSearch: handleSearched,
                            }}
                            farRightSettings={{
                                helpSettings: undefined,
                                feedbackSettings: {
                                    panelSettings: {
                                        ocvButtonIsFocused: false,
                                        onClick: () => {
                                            return true;
                                        },
                                        launchOptions: {
                                            categories: {
                                                show: false,
                                                customCategories: [],
                                            },
                                        },
                                    },
                                },
                                profileSettings: undefined,
                            }}
                            telemetryHook={telemetryClient}
                        />
                        <div className={navStyle}>
                            <Nav />
                        </div>
                        <Main tabIndex={-1} className={bannerStyle}>
                            <Routes />
                        </Main>
                        <Footer>
                            <AskHRFooter />
                        </Footer>
                    </>
                )}
            </BrowserRouter>
        </Customizer>
    );
}

render(
    <ShellWithStore>
        <App />
    </ShellWithStore>,
    document.getElementById('app')
);
